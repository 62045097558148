import { useFormik } from "formik";
import _, { isEmpty, reduce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { v4 as uuid4 } from "uuid";
import * as yup from "yup";
import { ValidationError } from "yup";
import {
  useLazyGetPayoutTemplateElementsQuery,
  useLazyGetPayoutTemplateQuery,
} from "api/baseAPI/payoutTemplates";
import {
  CreatePayoutQueryParams,
  PayoutKind,
  useCreatePayoutRegistryMutation,
  useLazyGetPayoutsAdditionalFieldsQuery,
} from "api/baseAPI/payouts";
import { useStyle } from "hooks/useStyle";
import { useErrorNotification } from "utils/notificationWrappers";
import { useUrlQuery } from "utils/url";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { luhnCreditCardValidation } from "utils/validateByLuhn";
import { IPayoutMethod } from "./payoutMethods";

export const PAYOUT_FIO_LIMIT: Record<string, number> = {
  uah: 30000,
};

export type PayoutData = {
  customer_account: string;
  amount: string;
  description: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  receiver_type?: string;
  tax_id?: string;
  tax_id_country?: string;
  AccHolderName?: string;
  BeneficiaryAddress?: string;
  BeneficiaryEmail?: string;
  BeneficiaryMobile?: string;
  IFSC?: string;
  sender_name?: string;
  fields?: Record<string, string>;
};

export function usePayoutForm(payoutMethod: IPayoutMethod) {
  const { t } = useTranslation();
  const style = useStyle();

  const [isSuccess, setIsSuccess] = useState(false);
  const [needSplitPayouts, setNeedSplitPayouts] = useState<boolean>(false);
  const [showAddToTemplateModal, setShowAddToTemplateModal] =
    useState<boolean>(false);
  const [isSplitting, setIsSplitting] = useState<boolean>(false);

  const [createPayoutRegistry] = useCreatePayoutRegistryMutation();

  const initialValues = {
    payouts: {
      [uuid4()]: {
        customer_account: "",
        amount: "",
        description: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        fields: {},
      },
    },
    delay: 0,
  };

  const [getAdditionalFields, additionalFieldsMeta] =
    useLazyGetPayoutsAdditionalFieldsQuery();

  useEffect(() => {
    if (payoutMethod.serviceId && payoutMethod.configKey) {
      getAdditionalFields({
        id: payoutMethod.serviceId,
        config_key: payoutMethod.configKey,
      });
    }
  }, [payoutMethod.serviceId]);

  const sortedAdditionalFields = useMemo(
    () =>
      payoutMethod.serviceId && payoutMethod.configKey
        ? additionalFieldsMeta.data
          ? [...additionalFieldsMeta.data].sort(
              (el, elNext) => el.listing_order - elNext.listing_order
            )
          : null
        : null,
    [additionalFieldsMeta.data, payoutMethod]
  );

  const form = useFormik<PayoutFormData>({
    initialValues,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: yup.object().shape({
      payouts: yup.lazy((value) => {
        if (!isEmpty(value)) {
          const validationObject = {
            customer_account: payoutMethod.customerAccountValidationSchema,
            amount: yup
              .number()
              .transform((val, origin) => (origin === "" ? null : val))
              .nullable()
              .positive(t("Введите положительное число"))
              .test(
                "isValid",
                t("Сумма не меньше {{amount}}", {
                  amount: payoutMethod.commissionsData?.min_amount || 0,
                }),
                (val: number | null | undefined): boolean => {
                  const minAmount =
                    payoutMethod.commissionsData?.min_amount || 0;
                  return !val || !minAmount || val >= minAmount;
                }
              )
              .max(1000000000, t("Сумма не может превышать 1000000000"))
              .required(t("Введите сумму"))
              .typeError(t("Неправильный формат")),
            description: yup
              .string()
              .max(100, t("Текст не может быть больше 100 символов")),
            first_name: yup
              .string()
              .when("amount", {
                is: (val: number) =>
                  val >
                    PAYOUT_FIO_LIMIT[
                      payoutMethod.currency?.toLowerCase() || ""
                    ] &&
                  style.is_fc &&
                  !sortedAdditionalFields?.find(
                    (item) => item.gate_key === "first_name"
                  ),
                then: yup.string().required(t("Введите имя")),
              })
              .matches(/^[a-zA-Zа-яА-ЯёЁєЄіІїЇґҐ]+$/, "Только буквы"),
            last_name: yup
              .string()
              .when("amount", {
                is: (val: number) =>
                  val >
                    PAYOUT_FIO_LIMIT[
                      payoutMethod.currency?.toLowerCase() || ""
                    ] &&
                  style.is_fc &&
                  !sortedAdditionalFields?.find(
                    (item) => item.gate_key === "last_name"
                  ),
                then: yup.string().required(t("Введите имя")),
              })
              .matches(/^[a-zA-Zа-яА-ЯёЁєЄіІїЇґҐ]+$/, t("Только буквы")),
            fields: yup.object().shape({
              ...sortedAdditionalFields?.reduce((acc, item) => {
                const reg = item.regex ? new RegExp(item.regex) : null;
                return {
                  ...acc,
                  [item.gate_key]: yup
                    .string()
                    .test("regex", t("Неправильный формат"), (val) =>
                      reg ? !val || reg.test(val || "") : true
                    )
                    .required(t("Обязательное поле")),
                };
              }, {}),
            }),
          };

          const newEntries = Object.keys(value).reduce(
            (acc, val) => ({
              ...acc,
              [val]: yup.object(validationObject),
            }),
            {}
          );

          return yup.object().shape(newEntries);
        }
        return yup.mixed().notRequired();
      }),
      delay: yup.number().max(3600, t("Максимальное значение 3600")),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (payoutMethod.commissionsData === null) {
        setSubmitting(false);
        return;
      }

      const { max_amount } = payoutMethod.commissionsData;

      setIsSuccess(false);
      setNeedSplitPayouts(false);
      let needStlit = false;

      Object.entries(values.payouts).forEach(([, dataItem]) => {
        if (max_amount && Number(dataItem.amount) > max_amount) {
          setNeedSplitPayouts(true);
          setSubmitting(false);
          needStlit = true;
        }
      });

      const { currency } = payoutMethod;

      if (
        !needStlit &&
        payoutMethod &&
        payoutMethod.serviceId &&
        payoutMethod.pointId &&
        payoutMethod.wallet &&
        currency !== null
      ) {
        const requestData: CreatePayoutQueryParams = {
          account_wallet_id: payoutMethod.wallet.id,
          point_id: payoutMethod.pointId,
          kind: payoutMethod.kind,
          service_id: payoutMethod.serviceId,
          payouts: Object.entries(values.payouts).map(([, payout]) => ({
            customer_account: payout.customer_account.replaceAll("+", ""),
            amount: Number(payout.amount),
            amount_currency: currency,
            description: payout.description,
            fields: payout.fields,
          })),
          delay: values.delay,
        };

        if (
          payoutMethod.slug === "phone" &&
          payoutMethod.additional_payout_information &&
          !isEmpty(payoutMethod.additional_payout_information)
        ) {
          requestData.additional_payout_information =
            payoutMethod.additional_payout_information;
        }

        createPayoutRegistry(requestData)
          .unwrap()
          .then(() => {
            setIsSuccess(true);
            resetForm({
              values: {
                ...initialValues,
              },
            });
          })
          .catch((res) => {
            if (
              res.status === 400 &&
              res.data[0] === "Attempt to create duplicate payout"
            ) {
              toast.error(t("Повторная отправка данных"));
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
  });

  useTranslateFormErrors(form);

  useEffect(() => {
    payoutMethod.notifyChangeErrors(form.errors);
  }, [form.errors]);

  const addRow = () => {
    form.setFieldValue("payouts", {
      ...form.values.payouts,
      [uuid4()]: {
        customer_account: "",
        amount: "",
        description: "",
        first_name: "",
        middle_name: "",
        last_name: "",
      },
    });
  };

  const deleteRow = (id: string) => {
    form.setFieldValue("payouts", _.omit(form.values.payouts, id));
  };

  const handleSplitPayouts = () => {
    setIsSplitting(true);
    setTimeout(() => {
      if (
        payoutMethod.commissionsData !== null &&
        payoutMethod.commissionsData.max_amount
      ) {
        form
          .setFieldValue(
            "payouts",
            splitPayouts(
              form.values.payouts,
              payoutMethod.commissionsData?.min_amount || 0,
              payoutMethod.commissionsData?.max_amount
            )
          )
          .finally(() => {
            setIsSplitting(false);
            setNeedSplitPayouts(false);
          });
      }
    }, 500);
  };

  return {
    form,
    isSuccess,
    needSplitPayouts,
    setNeedSplitPayouts,
    showAddToTemplateModal,
    setShowAddToTemplateModal,
    addRow,
    deleteRow,
    handleSplitPayouts,
    isSplitting,
    additionalFields: sortedAdditionalFields || null,
    isAdditionalFieldsLoading: additionalFieldsMeta.isLoading,
  };
}

export type Commissions = Record<string, { point: number; customer: number }>;

export type PayoutFormData = {
  payouts: Record<string, PayoutData>;
  delay: number;
};

export const getCustomerCommission = (
  amount: number,
  customer_fix: number | null,
  customer_percent: number | null
) => {
  if (amount <= 0) {
    return 0;
  }

  let commission = 0;
  let fix = 0;
  let percent = 1;

  if (customer_fix && customer_fix !== 0) {
    fix = customer_fix;
  }

  if (customer_percent && customer_percent !== 0) {
    percent = customer_percent;
  }

  if (percent !== 1) {
    commission = (amount * percent) / 100;
  }

  if (fix !== 0) {
    commission += fix;
  }

  return commission;
};

export const getPointCommission = (
  amount: number,
  point_fix: number | null,
  point_percent: number | null
) => {
  if (amount <= 0) {
    return 0;
  }

  let commission = 0;
  let fix = 0;
  let percent = 1;

  if (point_fix && point_fix !== 0) {
    fix = point_fix;
  }

  if (point_percent && point_percent !== 0) {
    percent = point_percent;
  }

  if (percent !== 1) {
    commission = (amount * percent) / 100;
  }

  if (fix !== 0) {
    commission += fix;
  }

  return commission;
};

export type ValidationResultType = Record<string, Record<string, string>>;

export const validateByCardPhoneAmount = (
  payouts: Record<string, PayoutData>,
  payoutType?: PayoutKind
): ValidationResultType => {
  const { t } = useTranslation();
  const schema = yup.object().shape({
    customer_account: yup
      .string()
      .when("$payoutType", {
        is: 1,
        then: yup
          .string()
          .test(
            "isValidByLuhn",
            t("Номер карты невалидный"),
            (value: string | undefined): boolean =>
              Boolean(value && luhnCreditCardValidation(value))
          )
          .required(t("Введите номер карты")),
      })
      .when("$payoutType", {
        is: 3,
        then: yup
          .string()
          .required(t("Необходимо ввести номер телефона или ID кошелька"))
          .test(
            "isValid",
            t("Необходимо ввести номер телефона или ID кошелька"),
            (value: string | undefined): boolean => {
              if (Number(value)) {
                return value?.length === 13;
              }
              return (
                value?.slice(0, 2) === "SP" &&
                value.length >= 5 &&
                value.length <= 32
              );
            }
          ),
      }),
    phone_number: yup.string().when("$payoutType", {
      is: 2,
      then: yup
        .string()
        .min(12, t("Неправильный формат номера телефона!"))
        .required(t("Введите номер телефона")),
    }),
    amount: yup
      .number()
      .transform((value, origin) => (origin === "" ? null : value))
      .nullable()
      .positive(t("Введите положительное число"))
      .max(1000000000, t("Сумма не может превышать 1000000000"))
      .required(t("Введите сумму"))
      .typeError(t("Неправильный формат")),
    description: yup
      .string()
      .max(100, t("Текст не может быть больше 100 символов")),
  });

  const validationResult: ValidationResultType = {};

  Object.keys(payouts).forEach((key) => {
    try {
      schema.validateSync(payouts[key], {
        abortEarly: false,
        context: { payoutType: payoutType || "account" },
      });
    } catch (e) {
      validationResult[key] = {};
      if (e instanceof ValidationError) {
        e.inner.forEach((errorItem: ValidationError) => {
          validationResult[key][String(errorItem.path)] = errorItem.message;
        });
      }
    }
  });

  return validationResult;
};

export const splitPayouts = (
  payouts: Record<string, PayoutData>,
  min: number,
  max: number
): Record<string, PayoutData> => {
  const outputPayouts: Record<string, PayoutData> = {};

  Object.keys(payouts).forEach((payoutKey) => {
    let amount = Number(payouts[payoutKey].amount);
    const { description } = payouts[payoutKey];

    if (amount <= max) {
      outputPayouts[payoutKey] = payouts[payoutKey];
    } else {
      let part_index = 1;

      while (amount > max) {
        // Set min in diapason as 75% of max
        const part_amount = randomInt(
          min && min + max >= amount ? min : max * 0.75,
          min && min + max >= amount && min < max - (min + max - amount)
            ? max - (min + max - amount)
            : max
        );

        outputPayouts[uuid4()] = {
          ...payouts[payoutKey],
          amount: String(part_amount),
          description: description
            ? `${description}; part ${part_index}`
            : `part ${part_index}`,
        };

        amount = Number((amount - part_amount).toFixed(2));
        part_index += 1;
      }

      outputPayouts[uuid4()] = {
        ...payouts[payoutKey],
        amount: String(amount),
        description: description
          ? `${description}; part ${part_index}`
          : `part ${part_index}`,
      };
    }
  });

  return outputPayouts;
};

export const randomInt = (min: number, max: number) => {
  const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const multiplesValue = process.env.REACT_APP_MULTIPLES_VALUE_PAYOUTS
    ? Number(process.env.REACT_APP_MULTIPLES_VALUE_PAYOUTS)
    : 100;
  return Math.floor(randomNumber / multiplesValue) * multiplesValue;
};

export const usePayoutsFromTemplate = (params: {
  payoutMethod: IPayoutMethod;
  cb: (data: Record<string, PayoutData>) => void;
}) => {
  const { queryParams, querySetters } = useUrlQuery({
    template_id: "",
  });

  const [getPayoutTemplate, getPayoutTemplateMeta] =
    useLazyGetPayoutTemplateQuery();

  const [getPayoutTemplateElements, getPayoutTemplateElementsMeta] =
    useLazyGetPayoutTemplateElementsQuery();

  useEffect(() => {
    if (queryParams.template_id) {
      getPayoutTemplate({ id: Number(queryParams.template_id) });
      getPayoutTemplateElements({
        template: Number(queryParams.template_id),
        page_size: 500,
      });
    }
  }, [params.payoutMethod, queryParams.template_id]);

  useErrorNotification([getPayoutTemplateMeta, getPayoutTemplateElementsMeta]);

  useEffect(() => {
    if (
      !isEmpty(getPayoutTemplateMeta.data) &&
      !isEmpty(getPayoutTemplateElementsMeta?.data?.results)
    ) {
      const data = reduce(
        getPayoutTemplateElementsMeta?.data?.results,
        (acc: any, item) => ({
          ...acc,
          [uuid4()]: {
            customer_account: item.customer_account,
            amount: item.amount,
            description: item.description,
            ...(!isEmpty(item.fields) && params.payoutMethod.configKey
              ? {
                  fields: { ...item.fields },
                }
              : {}),
          },
        }),
        {} as Record<string, PayoutData>
      );
      if (params.payoutMethod.kind === getPayoutTemplateMeta.data.kind) {
        params.cb(data);
      }
      querySetters.set_template_id("");
    }
  }, [getPayoutTemplateMeta.data, getPayoutTemplateElementsMeta?.data]);

  return {
    isLoading:
      getPayoutTemplateMeta.isLoading ||
      getPayoutTemplateElementsMeta.isLoading,
  };
};

import { FormikErrors } from "formik";
import { ReactElement, useEffect, useState } from "react";
import { RequiredStringSchema } from "yup/lib/string";
import { AnyObject } from "yup/lib/types";
import { PayoutKind } from "api/baseAPI/payouts";
import {
  CommissionResponse,
  useLazyGetAllServicesCommissionsQuery,
  // useLazyGetServicesCommissionsQuery,
} from "api/baseAPI/services";
import { Wallet } from "api/baseAPI/wallets";
import { useSelectedPointManager } from "hooks/useSelectedEntity";
import { CustomerAccountInputType } from "pages/payout/logic/forms/components/FormLines";
import { Crypto, Fiat, IPayoutCategory, UPI } from "pages/payout/logic/payoutsCategory";
import { Commissions, PayoutData, PayoutFormData } from "pages/payout/logic/payoutsCreateLogic";
import { Subscriber } from "./BasePayoutMethod";
import { BinancePayPayoutMethod } from "./BinancePayPayoutMethod";
import { BinancePayoutMethod } from "./BinancePayoutMethod";
import { CardPayoutMethod } from "./CardPayoutMethod";
import { ExmoPayoutMethod } from "./ExmoPayoutMethod";
import { KunaCodePayoutMethod } from "./KunaCodePayoutMethod";
import { PhonePayoutMethod } from "./PhonePayoutMethod";
import { PixPayoutMethod } from "./PixPayoutMethod";
import { SettlepayPayoutMethod } from "./SettlepayPayoutMethod";
import { UPIPayoutMethod } from "./UPIPayoutMethod";
import { WhiteBitPayoutMethod } from "./WhiteBitPayoutMethod";
import { IbanPayoutMethod } from "./IbanPayoutMethod";

export interface IPayoutMethod {
  slug: string;
  kind: PayoutKind;
  name: string;
  mobileName: string;
  icon: ReactElement;
  form: (pm: IPayoutMethod) => ReactElement;
  isActive: boolean;
  isLoaded: boolean;
  commissionsData: CommissionResponse | null;
  pointManager: ReturnType<typeof useSelectedPointManager> | null;
  serviceId: number | null;
  pointId: number | null;
  configKey: string | null;
  currency: string | null;
  csvExample: string | null;
  xlsxExample: string | null;
  jsonExample: Record<string, string | number>[] | null;
  filenameExample: string;
  wallet: Wallet | null;
  category: IPayoutCategory | null;
  customerAccountInput: CustomerAccountInputType;
  customerAccountValidationSchema: RequiredStringSchema<
    string | undefined,
    AnyObject
  >;
  errors: FormikErrors<PayoutFormData> | null;
  values: PayoutFormData | null;
  additional_payout_information?: null | {
    mobile_service_vodafone?: number;
    mobile_service_kyivstar?: number;
    mobile_service_life?: number;
  };

  resetForm: () => void;
  addSubscriber(key: string, s: Subscriber): void;
  notifyFormUpdate(payouts: Record<string, PayoutData>): void;
  notifyFormSaveToTemplate(isSave: boolean): void;
  notifyIsSubmiting(isSubmiting: boolean): void;
  notifyChangeDelay(delay: number): void;
  notifyChangeErrors(status: FormikErrors<PayoutFormData>): void;
  getCommissions(payouts: Record<string, PayoutData>): Commissions;
  handleSubmit(): void;
  handleValidate(): Promise<boolean>;
  handleSaveToTemplate(save: boolean): void;
}

export function usePayouts(
  currency: string,
  wallet: Wallet | null
): { payoutMethods: IPayoutMethod[]; loading: boolean } {
  const [loading, setLoading] = useState<boolean>(true);

  const selectedPointManager = useSelectedPointManager();

  const fiat = new Fiat();
  const crypto = new Crypto();
  const inr = new UPI();

  const defaultMethods = [
    new CardPayoutMethod(fiat),
    new IbanPayoutMethod(fiat),
    new PhonePayoutMethod(fiat),
    new SettlepayPayoutMethod(fiat),
    new PixPayoutMethod(fiat),

    new KunaCodePayoutMethod(crypto),
    new BinancePayoutMethod(crypto),
    new BinancePayPayoutMethod(crypto),
    new ExmoPayoutMethod(crypto),
    new WhiteBitPayoutMethod(crypto),

    new UPIPayoutMethod(inr),
  ];

  const [payoutMethods, setPayoutMethods] =
    useState<IPayoutMethod[]>(defaultMethods);

  const [getAllServiceCommissions] = useLazyGetAllServicesCommissionsQuery();

  useEffect(() => {
    if (!wallet) {
      return;
    }

    setLoading(true);

    const updatedPayoutMethods: IPayoutMethod[] = [];
    const queryList: IPayoutMethod[] = [];

    for (const payoutMethod of defaultMethods) {
      payoutMethod.pointManager = selectedPointManager;
      payoutMethod.currency = currency;
      payoutMethod.wallet = wallet;

      if (payoutMethod.slug === "phone") {
        payoutMethod.additional_payout_information = {};

        const {
          mobile_service_vodafone,
          mobile_service_kyivstar,
          mobile_service_life,
        } = selectedPointManager.service_config;

        if (mobile_service_vodafone) {
          payoutMethod.additional_payout_information.mobile_service_vodafone =
            mobile_service_vodafone;
        }

        if (mobile_service_life) {
          payoutMethod.additional_payout_information.mobile_service_life =
            mobile_service_life;
        }

        if (mobile_service_kyivstar) {
          payoutMethod.additional_payout_information.mobile_service_kyivstar =
            mobile_service_kyivstar;
        }
      }

      if (payoutMethod.serviceId && payoutMethod.pointId) {
        queryList.push(payoutMethod);
      }
    }

    getAllServiceCommissions(
      queryList
        .map((payoutMethod) => ({
          serviceId: payoutMethod.serviceId || 0,
          pointId: payoutMethod.pointId || 0,
        }))
        .reduce(
          (
            acc: {
              serviceId: number;
              pointId: number;
            }[],
            item
          ) => {
            const findEl = acc.find(
              (el) =>
                el.serviceId  ===  item.serviceId && el.pointId  ===  item.pointId
            );
            return [...acc, ...(findEl ? [] : [item])];
          },
          []
        )
    )
      .unwrap()
      .then((responses: { data: CommissionResponse; key: string }[]) => {
        for (const payoutMethod of queryList) {
          const findEl = responses.find(
            (el) =>
              el.key  === 
              `/services/${payoutMethod.serviceId}/get-commissions/?point=${payoutMethod.pointId}`
          );
          if (findEl?.data) {
            payoutMethod.commissionsData = findEl?.data;
            payoutMethod.isActive = true;
          } else {
            payoutMethod.commissionsData = null;
            payoutMethod.isActive = false;
          }
          payoutMethod.isLoaded = true;
          updatedPayoutMethods.push(payoutMethod);
        }
      })
      .catch((err) => {
        console.error("Error", err);
      })
      .finally(() => {
        setPayoutMethods([
          ...defaultMethods.map((pm) => {
            const updatedPm = updatedPayoutMethods.find(
              (upm) => upm.slug  ===  pm.slug
            );
            if (updatedPm) {
              updatedPm.isLoaded = true;
              return updatedPm;
            }
            pm.isLoaded = true;
            return pm;
          }),
        ]);
        setLoading(false);
      });
  }, [currency, wallet, selectedPointManager]);

  return { payoutMethods, loading };
}

import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import { Button } from "UI/Button";
import { Checkbox } from "UI/Form/Checkbox";
import { TextInput } from "UI/Form/TextInput";
import { Grid } from "UI/Grid";
import { Typography } from "UI/Typography";
import {
  CreatePayoutTemplateElement,
  PayoutTemplate,
  PayoutTemplateElement,
  useCreatePayoutTemplateElementMutation,
  useCreatePayoutTemplateMutation,
  useGetPayoutTemplatesQuery,
  useUpdatePayoutTemplateMutation,
} from "api/baseAPI/payoutTemplates";
import { PayoutKind } from "api/baseAPI/payouts";
import { LinearProgress } from "components/LinearProgress";
import {
  useErrorNotification,
  useSuccessNotification,
} from "utils/notificationWrappers";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { PayoutData } from "pages/payout/logic/payoutsCreateLogic";

export const AddPayoutToTemplateForm = ({
  currency,
  payoutType,
  pointManagerId,
  payouts,
  onCancel,
  configKey,
}: {
  currency: string;
  payoutType: PayoutKind;
  pointManagerId: number;
  payouts: Record<string, PayoutData>;
  onCancel: () => void;
  configKey: string;
}) => {
  const { t } = useTranslation();

  const [showAddNewTemplateForm, setShowAddNewTemplateForm] =
    useState<boolean>(false);

  const [createPayoutTemplateElement, createPayoutTemplateElementMeta] =
    useCreatePayoutTemplateElementMutation();

  const [updatePayoutTemplate, updatePayoutTemplateMeta] =
    useUpdatePayoutTemplateMutation();

  useSuccessNotification([
    createPayoutTemplateElementMeta,
    updatePayoutTemplateMeta,
  ]);
  useErrorNotification([
    createPayoutTemplateElementMeta,
    updatePayoutTemplateMeta,
  ]);

  const getPayoutTemplatesQuery = useGetPayoutTemplatesQuery({
    page: "1",
    page_size: "100",
    search: "",
    kind: String(payoutType),
  });

  const addElementsToTemplate = (
    templateId: number,
    payoutData: PayoutData[]
  ): Promise<PayoutTemplateElement[]> => {
    const createElementPromisesList: Promise<PayoutTemplateElement>[] = [];

    payoutData.forEach((payout) => {
      let fields = {};

      // PIX hack
      if (payout.tax_id && payout.tax_id_country) {
        fields = {
          tax_id: payout.tax_id,
          tax_id_country: payout.tax_id_country,
        };
      }

      // UPI hack
      if (payout.AccHolderName) {
        fields = {
          AccHolderName: payout.AccHolderName,
          BeneficiaryAddress: payout.BeneficiaryAddress,
          BeneficiaryEmail: payout.BeneficiaryEmail,
          BeneficiaryMobile: payout.BeneficiaryMobile,
          IFSC: payout.IFSC,
        };
      }
      // BinancePay hack
      if (payout.receiver_type) {
        fields = {
          receiver_type: payout.receiver_type,
        };
      }
      // Card additional fields hack
      if (!isEmpty(payout.fields) && configKey) {
        fields = { ...payout.fields };
      }
      createElementPromisesList.push(
        createPayoutTemplateElement({
          customer_account:
            payoutType === 1
              ? payout.customer_account.replaceAll(" ", "")
              : payout.customer_account,
          amount_currency: currency,
          amount: payout.amount,
          description: payout.description,
          template: templateId,
          fields,
        }).unwrap()
      );
    });

    return new Promise((resolve, reject) => {
      Promise.all(createElementPromisesList)
        .then((values) => {
          resolve(values);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const replaceElementsInTemplate = (
    templateId: number,
    payoutData: PayoutData[]
  ) => {
    const elements: CreatePayoutTemplateElement[] = [];

    payoutData.forEach((payout) => {
      let fields = {};

      // IBAN hack
      if (payoutType === 12) {
        fields = {
          tax_id: payout.tax_id,
          first_name: payout.first_name,
          last_name: payout.last_name,
          sender_name: payout.sender_name,
        };
      }

      // PIX hack
      if (payout.tax_id && payout.tax_id_country) {
        fields = {
          tax_id: payout.tax_id,
          tax_id_country: payout.tax_id_country,
        };
      }

      // UPI hack
      if (payout.AccHolderName) {
        fields = {
          AccHolderName: payout.AccHolderName,
          BeneficiaryAddress: payout.BeneficiaryAddress,
          BeneficiaryEmail: payout.BeneficiaryEmail,
          BeneficiaryMobile: payout.BeneficiaryMobile,
          IFSC: payout.IFSC,
        };
      }
      // BinancePay hack
      if (payout.receiver_type) {
        fields = {
          receiver_type: payout.receiver_type,
        };
      }
      // Card additional fields hack
      if (!isEmpty(payout.fields) && configKey) {
        fields = { ...payout.fields };
      }

      elements.push({
        customer_account:
          payoutType === 1
            ? payout.customer_account.replaceAll(" ", "")
            : payout.customer_account,
        amount_currency: currency,
        amount: payout.amount,
        description: payout.description,
        template: templateId,
        fields,
      });
    });

    return new Promise((resolve, reject) => {
      updatePayoutTemplate({
        id: templateId,
        elements,
      })
        .unwrap()
        .then((value) => {
          resolve(value);
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const [confirmQueue, setConfirmQueue] = useState<number[]>([]);

  const form = useFormik<{
    templates: { id: number; title: string; isActive: boolean }[];
  }>({
    initialValues: {
      templates: [],
    },
    onSubmit: (values, { setSubmitting }) => {
      const templatesForWork = values.templates.filter((tmp) => tmp.isActive);

      if (templatesForWork.length === 0) {
        setSubmitting(false);
        return;
      }

      const addElementsToTemplatePromises: Promise<PayoutTemplateElement[]>[] =
        [];

      const preQueue: number[] = [];

      templatesForWork.forEach((template) => {
        const payoutTemplate: PayoutTemplate | undefined =
          getPayoutTemplatesQuery.data?.results.find(
            (tmp) => tmp.id === template?.id
          );

        if (payoutTemplate) {
          if (payoutTemplate.element_count > 0) {
            preQueue.push(template.id);
          } else {
            addElementsToTemplatePromises.push(
              addElementsToTemplate(payoutTemplate.id, Object.values(payouts))
            );
          }
        }
      });

      Promise.all(addElementsToTemplatePromises).finally(() => {
        setSubmitting(false);
        if (preQueue.length > 0) {
          setConfirmQueue(preQueue);
        } else {
          onCancel();
        }
      });
    },
  });

  const handleReplaceElementsToTemplate = (templateId: number) => {
    replaceElementsInTemplate(templateId, Object.values(payouts)).finally(
      () => {
        setConfirmQueue((prev) => {
          if (prev.length === 1) {
            onCancel();
            return [];
          }
          return prev.filter((id) => id !== templateId);
        });
      }
    );
  };

  const handleAddElementsToTemplate = (templateId: number) => {
    addElementsToTemplate(templateId, Object.values(payouts)).finally(() => {
      setConfirmQueue((prev) => {
        if (prev.length === 1) {
          onCancel();
          return [];
        }
        return prev.filter((id) => id !== templateId);
      });
    });
  };

  const [createPayoutTemplate, createPayoutTemplateMeta] =
    useCreatePayoutTemplateMutation();

  useSuccessNotification([createPayoutTemplateMeta]);

  const addNewTemplateForm = useFormik<{ title: string }>({
    initialValues: {
      title: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      createPayoutTemplate({
        point_manager_id: pointManagerId,
        title: values.title,
        kind: payoutType,
        elements: [],
      })
        .unwrap()
        .then(() => {
          setShowAddNewTemplateForm(false);
          addNewTemplateForm.resetForm();
          setTimeout(() => getPayoutTemplatesQuery.refetch());
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required(t("Обязательное поле"))
        .max(100, t("Текст не может быть больше 100 символов")),
    }),
  });

  useTranslateFormErrors(addNewTemplateForm);

  useEffect(() => {
    if (getPayoutTemplatesQuery.isSuccess) {
      form.setFieldValue(
        "templates",
        getPayoutTemplatesQuery.data?.results?.map((item) => ({
          ...item,
          isActive: false,
        }))
      );
    }
  }, [getPayoutTemplatesQuery]);

  // Confirm dialog for processing queue
  if (confirmQueue.length > 0) {
    const activeTemplateId = confirmQueue[0];

    if (
      updatePayoutTemplateMeta.isLoading ||
      createPayoutTemplateElementMeta.isLoading
    ) {
      return <LinearProgress />;
    }

    return (
      <Grid
        container
        p={24}
        direction="column"
        justifyContent="space-between"
        style={{ minHeight: "330px" }}
      >
        <Grid item sm="fill">
          <Typography
            variant="h2"
            fontSize={20}
            fontWeight="bold"
            textAlign="center"
            mb={24}
          >
            {t(
              'В шаблоне "{{templateName}}" уже есть выплаты. Добавить в него новые элементы или перезаписать существующие?',
              {
                templateName: form.values.templates.find(
                  (tmp) => tmp.id === activeTemplateId
                )?.title,
              }
            )}
          </Typography>
        </Grid>

        <Grid item sm={12}>
          <Grid container hSpace={15}>
            <Grid item sm="fill">
              <Button
                onClick={() => handleAddElementsToTemplate(activeTemplateId)}
                fullwidth
              >
                {t("Добавить")}
              </Button>
            </Grid>

            <Grid item sm="fill">
              <Button
                onClick={() =>
                  handleReplaceElementsToTemplate(activeTemplateId)
                }
                fullwidth
              >
                {t("Перезаписать")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  if (showAddNewTemplateForm) {
    return (
      <Grid container p={24}>
        <Grid item sm={12}>
          <Typography
            variant="h2"
            fontSize={20}
            fontWeight="bold"
            textAlign="center"
            mb={24}
          >
            {t("Новый шаблон")}
          </Typography>
        </Grid>
        <Grid item sm={12} mb={24}>
          <TextInput
            label={t("Название шаблона")}
            placeholder={t("Название шаблона")}
            value={addNewTemplateForm.values.title}
            onChange={(val) => addNewTemplateForm.setFieldValue("title", val)}
            error={addNewTemplateForm.errors.title}
            helperText={addNewTemplateForm.errors.title}
            fullwidth
          />
        </Grid>
        <Grid item sm="fill">
          <Grid container hSpace={10}>
            <Grid item sm={6}>
              <Button
                fullwidth
                variant="outlined"
                onClick={() => setShowAddNewTemplateForm(false)}
              >
                {t("Отмена")}
              </Button>
            </Grid>
            <Grid item sm={6}>
              <Button
                fullwidth
                variant="contained"
                isLoading={addNewTemplateForm.isSubmitting}
                onClick={() => addNewTemplateForm.submitForm()}
              >
                {t("Подтвердить")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container p={24}>
      <Grid item sm="fill">
        <Typography
          variant="h2"
          fontSize={20}
          fontWeight="bold"
          textAlign="center"
          mb={24}
        >
          {t("Выберите шаблон, где сохранить выплаты")}
        </Typography>
      </Grid>
      <Grid item sm={12} mt={24} mb={24}>
        {getPayoutTemplatesQuery.isFetching ? (
          <LinearProgress mb={15} />
        ) : (
          form.values.templates.map((template, index) => (
            <Checkbox
              mb={12}
              key={template.id}
              onChange={(val) =>
                form.setFieldValue(`templates[${index}]`, {
                  ...template,
                  isActive: val,
                })
              }
              value={template.isActive}
              label={template.title}
            />
          ))
        )}
        <Button
          variant="contained"
          onClick={() => {
            setShowAddNewTemplateForm(true);
            getPayoutTemplatesQuery.refetch();
          }}
        >
          {t("Добавить шаблон")}
        </Button>
      </Grid>
      <Grid item sm="fill">
        <Grid container hSpace={10}>
          <Grid item sm={6}>
            <Button fullwidth variant="outlined" onClick={() => onCancel()}>
              {t("Отмена")}
            </Button>
          </Grid>
          <Grid item sm={6}>
            <Button
              fullwidth
              variant="contained"
              onClick={() => form.handleSubmit()}
              isLoading={form.isSubmitting}
            >
              {t("Подтвердить")}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

import { ReactElement } from "react";
import * as yup from "yup";
import { IBANPayoutForm } from "pages/payout/logic/forms/IBANPayoutForm";
import { ReactComponent as IbanIcon } from "utils/img/iban.svg";
import { BasePayoutMethod } from "./BasePayoutMethod";
import { IPayoutMethod } from "./index";

export class IbanPayoutMethod
  extends BasePayoutMethod
  implements IPayoutMethod
{
  slug: string = "iban";

  kind: 12 = 12;

  name: string = "Платеж по IBAN";

  mobileName: string = "IBAN";

  icon: ReactElement = (<IbanIcon />);

  filenameExample = "sample-iban";

  jsonExample = [
    {
      "Номер счета (IBAN)": "UA213052990000000000000000000",
      Сумма: 23.33,
      Примечание: "note 1",
      "ИНН получателя": "",
      "Фамилия получателя": "",
      "Имя получателя": "",
      Отправитель: "",
    },
    {
      "Номер счета (IBAN)": "UA213007110000000000000000000",
      Сумма: 50,
      Примечание: "note 2",
      "ИНН получателя": "",
      "Фамилия получателя": "",
      "Имя получателя": "",
      Отправитель: "",
    },
    {
      "Номер счета (IBAN)": "UA213348510000000000000000000",
      Сумма: 5050,
      Примечание: "note 3",
      "ИНН получателя": "",
      "Фамилия получателя": "",
      "Имя получателя": "",
      Отправитель: "",
    },
  ];

  customerAccountInput = () => <></>;

  form: (payoutMethod: IPayoutMethod) => ReactElement = (payoutMethod) => (
    <IBANPayoutForm payoutMethod={payoutMethod} />
  );

  customerAccountValidationSchema = yup.string().required("Введите IBAN");

  get serviceId() {
    return this.pointManager?.service_config?.iban_payouts || null;
  }

  get pointId() {
    return this.pointManager?.point_id || null;
  }

  get configKey() {
    return "iban_payouts";
  }
}

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import _, { isEmpty, reduce } from "lodash";
import { v4 as uuid4 } from "uuid";
import * as yup from "yup";
import {
  useLazyGetPayoutTemplateElementsQuery,
  useLazyGetPayoutTemplateQuery,
} from "api/baseAPI/payoutTemplates";
import { useCreatePayoutRegistryMutation } from "api/baseAPI/payouts";
import { useErrorNotification } from "utils/notificationWrappers";
import { useUrlQuery } from "utils/url";
import { useTranslateFormErrors } from "utils/useTranslateFormErrors";
import { IPayoutMethod } from "./payoutMethods";
import { splitPayouts } from "./payoutsCreateLogic";

export type PayoutData = {
  customer_account: string;
  amount: string;
  description: string;
  tax_id: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  sender_name: string;
};

export type PayoutFormData = {
  delay: number;
  saveCardsToTemplate: boolean;
  payouts: Record<string, PayoutData>;
  fields: Record<string, string>;
};

export function useIbanPayoutForm(payoutMethod: IPayoutMethod) {
  const { t } = useTranslation();

  const [isSuccess, setIsSuccess] = useState(false);
  const [needSplitPayouts, setNeedSplitPayouts] = useState<boolean>(false);
  const [showAddToTemplateModal, setShowAddToTemplateModal] =
    useState<boolean>(false);
  const [isSplitting, setIsSplitting] = useState<boolean>(false);

  const [createPayoutRegistry] = useCreatePayoutRegistryMutation();

  const initialValues = {
    delay: 0,
    saveCardsToTemplate: false,
    payouts: {
      [uuid4()]: {
        customer_account: "",
        amount: "",
        description: "",
        tax_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        sender_name: "",
      },
    },
    fields: {},
  };

  const form = useFormik<PayoutFormData>({
    initialValues,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: yup.object().shape({
      payouts: yup.lazy((value) => {
        if (!isEmpty(value)) {
          const validationObject = {
            customer_account: yup
              .string()
              .test(
                "isValid",
                t("Неверный номер счета (IBAN)"),
                (val: string | undefined): boolean =>
                  !val || (!!val && /^UA\d{27}$/.test(val))
              )
              .required(t("Введите IBAN")),
            amount: yup
              .number()
              .transform((val, origin) => (origin === "" ? null : val))
              .nullable()
              .positive(t("Введите положительное число"))
              .test(
                "isValid",
                t("Сумма не меньше {{amount}}", {
                  amount: payoutMethod.commissionsData?.min_amount || 0,
                }),
                (val: number | null | undefined): boolean => {
                  const minAmount =
                    payoutMethod.commissionsData?.min_amount || 0;
                  return !val || !minAmount || val >= minAmount;
                }
              )
              .max(1000000000, t("Сумма не может превышать 1000000000"))
              .required(t("Введите сумму"))
              .typeError(t("Неправильный формат")),
            tax_id: yup.string().length(10, t("Неправильный формат")),
            description: yup
              .string()
              .max(100, t("Текст не может быть больше 100 символов")),
            first_name: yup
              .string()
              .matches(/^[a-zA-ZА-яА-Я]+$/, t("Только буквы")),
            last_name: yup
              .string()
              .matches(/^[a-zA-ZА-яА-Я]+$/, t("Только буквы")),
            sender_name: yup
              .string()
              .matches(
                /^[a-zA-Zа-яА-ЯёЁїЇіІєЄґҐ]+( [a-zA-Zа-яА-ЯёЁїЇіІєЄґҐ]+)+$/,
                t("Неправильный формат")
              ),
          };

          const newEntries = Object.keys(value).reduce(
            (acc, val) => ({
              ...acc,
              [val]: yup.object(validationObject),
            }),
            {}
          );

          return yup.object().shape(newEntries);
        }
        return yup.mixed().notRequired();
      }),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (payoutMethod.commissionsData === null) {
        setSubmitting(false);
        return;
      }

      const { max_amount } = payoutMethod.commissionsData;

      setIsSuccess(false);
      setNeedSplitPayouts(false);
      let needStlit = false;

      Object.entries(values.payouts).forEach(([, dataItem]) => {
        if (max_amount && Number(dataItem.amount) > max_amount) {
          setNeedSplitPayouts(true);
          setSubmitting(false);
          needStlit = true;
        }
      });

      const { currency } = payoutMethod;

      if (
        !needStlit &&
        payoutMethod &&
        payoutMethod.serviceId &&
        payoutMethod.pointId &&
        payoutMethod.wallet &&
        currency !== null
      ) {
        createPayoutRegistry({
          account_wallet_id: payoutMethod.wallet.id,
          point_id: payoutMethod.pointId,
          kind: payoutMethod.kind,
          service_id: payoutMethod.serviceId,
          payouts: Object.entries(values.payouts).map(([, payout]) => ({
            customer_account: payout.customer_account,
            amount: Number(payout.amount),
            amount_currency: currency,
            description: payout.description,
            fields: {
              tax_id: payout.tax_id,
              first_name: payout.first_name,
              last_name: payout.last_name,
              sender_name: payout.sender_name,
            },
          })),
        })
          .unwrap()
          .then(() => {
            setIsSuccess(true);
            if (form.values.saveCardsToTemplate) {
              setShowAddToTemplateModal(true);
            } else {
              resetForm({
                values: {
                  ...initialValues,
                },
              });
            }
          })
          .catch((res) => {
            if (
              res.status === 400 &&
              res.data[0] === "Attempt to create duplicate payout"
            ) {
              toast.error(t("Повторная отправка данных"));
            }
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    },
  });

  useTranslateFormErrors(form);

  const addRow = () => {
    form.setFieldValue("payouts", {
      ...form.values.payouts,
      [uuid4()]: {
        customer_account: "",
        amount: "",
        description: "",
        tax_id: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        sender_name: "",
      },
    });
  };

  const deleteRow = (id: string) => {
    form.setFieldValue("payouts", _.omit(form.values.payouts, id));
  };

  const handleSplitPayouts = () => {
    setIsSplitting(true);
    setTimeout(() => {
      if (
        payoutMethod.commissionsData !== null &&
        payoutMethod.commissionsData.max_amount
      ) {
        form
          .setFieldValue(
            "payouts",
            splitPayouts(
              form.values.payouts,
              payoutMethod.commissionsData?.min_amount || 0,
              payoutMethod.commissionsData?.max_amount
            )
          )
          .finally(() => {
            setIsSplitting(false);
            setNeedSplitPayouts(false);
          });
      }
    }, 500);
  };

  return {
    form,
    isSuccess,
    needSplitPayouts,
    setNeedSplitPayouts,
    showAddToTemplateModal,
    setShowAddToTemplateModal,
    addRow,
    deleteRow,
    handleSplitPayouts,
    isSplitting,
  };
}

export const usePayoutsFromTemplate = (params: {
  payoutMethod: IPayoutMethod;
  cb: (data: Record<string, PayoutData>) => void;
}) => {
  const { queryParams, querySetters } = useUrlQuery({
    template_id: "",
  });

  const [getPayoutTemplate, getPayoutTemplateMeta] =
    useLazyGetPayoutTemplateQuery();

  const [getPayoutTemplateElements, getPayoutTemplateElementsMeta] =
    useLazyGetPayoutTemplateElementsQuery();

  useEffect(() => {
    if (queryParams.template_id) {
      getPayoutTemplate({ id: Number(queryParams.template_id) });
      getPayoutTemplateElements({
        template: Number(queryParams.template_id),
        page_size: 500,
      });
    }
  }, [params.payoutMethod, queryParams.template_id]);

  useErrorNotification([getPayoutTemplateMeta, getPayoutTemplateElementsMeta]);

  useEffect(() => {
    if (
      !isEmpty(getPayoutTemplateMeta.data) &&
      !isEmpty(getPayoutTemplateElementsMeta?.data?.results)
    ) {
      const data = reduce(
        getPayoutTemplateElementsMeta?.data?.results,
        (acc: any, item) => ({
          ...acc,
          [uuid4()]: {
            customer_account: item.customer_account,
            amount: item.amount,
            description: item.description,
            tax_id: item.fields?.tax_id || "",
            first_name: item.fields?.first_name || "",
            last_name: item.fields?.last_name || "",
            middle_name: "",
            sender_name: item.fields?.sender_name || "",
          },
        }),
        {} as Record<string, PayoutData>
      );
      if (params.payoutMethod.kind === getPayoutTemplateMeta.data.kind) {
        params.cb(data);
      }
      querySetters.set_template_id("");
    }
  }, [getPayoutTemplateMeta.data, getPayoutTemplateElementsMeta?.data]);

  return {
    isLoading:
      getPayoutTemplateMeta.isLoading ||
      getPayoutTemplateElementsMeta.isLoading,
  };
};

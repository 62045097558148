import { baseSplitApi } from "./index";

export interface PointManagerItem {
  available_components: {
    id: number;
    name: string;
    display_name: string;
    listing_order: number;
    parent: number;
  }[];
  available_wallets: { id: number; name: string; currency: string }[];
  payout_wallets: { id: number; name: string; currency: string }[];
  main_wallet: { id: number; name: string; currency: string };
  point: { id: number; name: string; department: number };
  point_id: number;
  access_level: { id: number; name: string };
  user: { id: number; username: string; account_id: number };
  id: number;
  is_admin: boolean;
  service_config: {
    card_payouts: number;
    default_phone_payout_service: number | Record<string, number>;
    phone_payouts_available: boolean;
    settlepay_payouts: number | Record<string, number>;
    card_top_up: number;
    binance_top_up: Record<string, number>;
    wallet_crypto_top_up: Record<string, number>;
    binance_id_payouts: Record<string, number>;
    exmo_create_code: Record<string, number>;
    binance_create_code: Record<string, number>;
    whitebit_create_code: Record<string, number>;
    kuna_create_code: Record<string, number>;
    pix_payouts: Record<string, number>;
    upi_payouts: Record<string, number>;
    invoicing_service: Record<string, number>;
    payouts_custom_delay_interface: boolean;
    mobile_service_vodafone: number;
    mobile_service_kyivstar: number;
    mobile_service_life: number;
    iban_payouts: number;
  };
}

type PointManagerQueryParams = {
  page: string;
  page_size: string;
  search: string;
  point_id: number;
};

export const pointManagersApi = baseSplitApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getPointManagers: builder.query<
      { count: number; results: PointManagerItem[] },
      PointManagerQueryParams
    >({
      query: (params) => {
        const query = new URLSearchParams();
        Object.entries(params).forEach(([key, value]) => {
          if (value) {
            query.append(key, String(value));
          } else {
            query.delete(key);
          }
        });
        return `/point-managers/?${query.toString()}`;
      },
      providesTags: ["PointManagers"],
    }),
    getPointManager: builder.query<PointManagerItem, { id: number }>({
      query: ({ id }) => `/point-managers/${id}/`,
      providesTags: ["PointManager"],
    }),
    createPointManager: builder.mutation<
      PointManagerItem,
      {
        available_components: number[];
        available_wallets: number[];
        email: string;
        access_level: number;
        point_id: number;
      }
    >({
      query: (data) => ({
        url: "/point-managers/",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["PointManagers", "PointManager"],
    }),
    updatePointManager: builder.mutation<
      PointManagerItem,
      {
        id: number;
        available_components: number[];
        available_wallets: number[];
        access_level: number;
        user: string;
        point_id: number;
      }
    >({
      query: ({ id, ...data }) => ({
        url: `/point-managers/${id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["PointManagers", "PointManager"],
    }),
    deletePointManager: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/point-managers/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["PointManagers", "PointManager"],
    }),

    getMerchantGroups: builder.query<
      { count: number; results: { id: number; name: string }[] },
      void
    >({
      query: () => {
        const query = new URLSearchParams();
        return `/merchant-groups/?${query.toString()}`;
      },
    }),

    getPointManagerByUser: builder.query<
      { count: number; results: PointManagerItem[] },
      { user: number }
    >({
      query: ({ user }) => {
        const query = new URLSearchParams();
        query.append("user", String(user));
        return `/point-managers/?${query.toString()}`;
      },
    }),
  }),
});

export const {
  useGetPointManagersQuery,
  useLazyGetPointManagerQuery,
  useCreatePointManagerMutation,
  useUpdatePointManagerMutation,
  useDeletePointManagerMutation,
  useLazyGetPointManagerByUserQuery,
  useGetMerchantGroupsQuery,
} = pointManagersApi;
